import { render, staticRenderFns } from "./LightboxVideoCard.vue?vue&type=template&id=515004d2&scoped=true&"
import script from "./LightboxVideoCard.vue?vue&type=script&lang=js&"
export * from "./LightboxVideoCard.vue?vue&type=script&lang=js&"
import style0 from "./LightboxVideoCard.vue?vue&type=style&index=0&id=515004d2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515004d2",
  null
  
)

export default component.exports